import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

// Components.
import Layout from "../components/layout";

// Assets
import ContactStyles from "../components/css-modules/contact.module.scss";

const ContactPage = ({ data }) => {
  const { node } = data.contactData.edges[0];
  const after = typeof window !== "undefined" ? window.location.href : "";

  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className={ContactStyles.grid}>
        <div className="contact-summary">
          <h1 className={ContactStyles.title}>{node.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: node.html }} />
        </div>
      </div>
    </Layout>
  );
};

export const frontmatter = {
  title: `Contact`,
  path: `/contact`,
  description: ``,
  weight: 40
};

export const ContactQuery = graphql`
  query ContactDataQuery {
    contactData: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "contact" } } }
      limit: 1
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;

export default ContactPage;
